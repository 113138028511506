import fetchUmbracoData from '@limbo-works/umbraco-client/client';

import { getDoctypeFromSolutionAndTemplate } from '~/assets/js/doctypes';
import { getLayoutFromSolutionAndTemplate } from '~/assets/js/layouts';

const DEFAULT_SOLUTION_NAME = 'main';

const state = () => ({
	template: null,
	error: null,
	site: null,
	current: {
		page: null,
		doctype: null,
		guid: null,
	},
});

const mutations = {
	SET_TEMPLATE(state, template) {
		state.template = template;
	},

	SET_ERROR_DATA(state, error) {
		state.error = error;
	},

	SET_SITE_DATA(state, site) {
		if (!site) {
			return;
		}

		state.site = site;
	},
};

const actions = {
	async nuxtServerInit({ dispatch }, { error, redirect, route }) {
		const data = await fetchUmbracoData({
			error,
			params: {
				...(process.env.NODE_ENV === 'development'
					? { cache: false }
					: {}),
				parts: ['site'],
			},
			redirect,
			route,
		});

		if (!data) {
			return;
		}

		dispatch('SAVE_DATA', data);
	},
	async FETCH_NAVIGATION(context, payload) {
		const { pageId, navContext, navLevels } = payload;

		const navigation = await this.$getNavigation({
			nodeId: pageId,
			navContext,
			navLevels,
		});

		return navigation;
	},

	async SAVE_DATA({ commit }, payload) {
		commit('SET_SITE_DATA', payload.site);
		// commit('SET_MISC_DATA', payload);
		// commit('SET_CURRENT_DATA', payload);
	},
};

const getters = {
	solution: ({ site }) => site?.alias ?? DEFAULT_SOLUTION_NAME,

	layout: ({ template }, { solution }) =>
		getLayoutFromSolutionAndTemplate(solution, template),

	doctype: ({ template }, { solution }) =>
		getDoctypeFromSolutionAndTemplate(solution, template),

	primaryNavigation: (state) =>
		(state.site?.navigation?.main ?? []).filter(
			({ isVisible }) => isVisible
		),

	footer: (state) => state.site?.footer ?? [],

	secondaryNavigation: (state) =>
		(state.site?.navigation?.secondary ?? []).filter(
			({ isVisible }) => isVisible
		),
};

export { state, actions, getters, mutations };
