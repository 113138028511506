<template>
	<Component :is="doctype" v-if="doctype" v-bind="$data" />
</template>

<script>
import fetchUmbracoData from '@limbo-works/umbraco-client/client';
import { mapGetters } from 'vuex';

import '../utils/v-tracking.js';

import doctypes, {
	getDoctypeFromSolutionAndTemplate,
} from '~/assets/js/doctypes';
import key from '~/assets/js/page-key';

export default {
	name: 'WildcardPage',

	key: (route) => key(route),

	components: {
		...doctypes,
	},

	layout: ({
		store: {
			getters: { layout },
		},
	}) => layout,

	async asyncData({ error, redirect, route, store: { state, commit } }) {
		const data = await fetchUmbracoData({
			error,
			params: {
				...(process.env.NODE_ENV === 'development'
					? { cache: false }
					: {}),
				parts: ['content'].filter(Boolean),
			},
			redirect,
			route,
		});

		// NOTE: if data is falsy, a redirect has taken place, or an error has been handled
		if (!data) {
			return;
		}

		const { content = {} } = data;

		if (content.template === 'ErrorPage') {
			commit('SET_ERROR_DATA', content);

			return;
		}

		commit('SET_TEMPLATE', content.template);

		return content;
	},

	head() {
		const meta = this.meta ?? {};

		if (meta.meta) {
			meta.meta.push({
				hid: 'google-site-verification',
				name: 'google-site-verification',
				content: 'OB1oYkeFi8EnI9VNJ3KM0eJezcgWYHAYs_EnNQxNIqM',
			});
		}

		return {
			...meta,
			script: [
				{
					hid: 'siteimprove',
					once: true,
					//
					innerHTML: `
					(function() {
						var scriptActivated = false;
						var setScript = function setScript() {
							if (!scriptActivated && window.CookieInformation && window.CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
								scriptActivated = true; // Make sure it only happens once
								/*<![CDATA[*/
								(function() {
									var sz = document.createElement('script'); sz.type = 'text/javascript'; sz.async = true;
									sz.src = '//siteimproveanalytics.com/js/siteanalyze_273532.js';
									var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sz, s);
								})();
								/*]]>*/
							}
						};
						setScript();
						window.addEventListener('CookieInformationConsentGiven', setScript);
					})()`,

					async: false,
					defer: true,
				},
			],
			__dangerouslyDisableSanitizersByTagID: {
				siteimprove: ['innerHTML'],
			},
		};
	},

	computed: {
		...mapGetters(['solution']),

		doctype() {
			const doctype = getDoctypeFromSolutionAndTemplate(
				this.solution,
				this.template
			);
			if (Object.keys(doctypes).includes(doctype)) {
				return doctype;
			}
			return null;
		},
	},
};
</script>
